<template>
  <div>
    <app-spinner v-if="!is_loaded" />
    <div v-else class="content equipment">
      <app-cells>
        <button @click="onShowModal" class="btn-back"></button>
        <h1 class="title">{{ $t('equipment.title') }}</h1>
      </app-cells>
      <p class="equipment__name">{{ $t('equipment.hello') }}, {{ client.first_name }} {{ client.last_name }}</p>
      <div class="equipment__infos">
        <div class="equipment__info">
          <span>{{ $t('equipment.route') }}:</span>
          <span>{{ group.route.name }}</span>
        </div>
        <div class="equipment__info">
          <span>{{ $t('equipment.duration') }}:</span>
          <span>{{ group.route.duration }} {{ $t('equipment.days') }}</span>
        </div>
        <div class="equipment__info">
          <span>{{ $t('equipment.start') }}:</span>
          <span>{{ formatDate(group.tour_start) }}</span>
        </div>
        <div class="equipment__info">
          <span>{{ $t('equipment.finish') }}:</span>
          <span>{{ formatDate(group.tour_end) }}</span>
        </div>
      </div>
      <p v-if="branding === 'false'" class="equipment__text">{{ $t('equipment.text') }}</p>
      <p v-if="branding === 'true'" class="equipment__text">{{ $t('equipment.text_branding') }}</p>
      <h2 class="equipment__title">{{ $t('equipment.table.title') }}</h2>
      <div v-if="equipments.length" class="equipment__table">
        <div v-for="(item, index) of equipments" :key="index" class="equipment__line">
          <div class="equipment__line-left">
            <span class="equipment__line-name">{{ item.public_name }}</span>
            <div class="equipment__line-switcher" :class="{ 'equipment__line-switcher--hide': item.out_of_stock }">
              <span class="equipment__line-have" :class="{'equipment__line-have--active': !item.status}">{{ $t('equipment.table.have') }}</span>
              <app-switcher v-model="item.status" :readonly="item.out_of_stock" />
              <span class="equipment__line-have" :class="{'equipment__line-have--active': item.status}">{{ $t('equipment.table.rent') }}</span>
            </div>
            <span v-if="item.out_of_stock" class="equipment__line-out">{{ $t('equipment.table.out') }}</span>
          </div>
          <div class="equipment__line-right" :class="{'equipment__line-right--show': item.status && !item.out_of_stock}">
            <span class="equipment__line-price">${{ item.total_price }}</span>
            <div class="equipment__line-size">
              <v-select
                  v-model="item.selected_size"
                  :options="item.sizes"
                  :filterable="false"
                  :clearable="false"
                  :searchable="false"
                  label="name"
                  placeholder="Size"
                  class="select"
                  :readonly="item.sizes.length === 1"
              >
                <template #open-indicator>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
            </div>
            <div class="equipment__line-count">
              <span>{{ item.measurement.name }}:</span>
              <div class="equipment__line-input">
                <app-input
                    v-model.number="item.count"
                    @input.native="onSetCount(item)"
                    min="0"
                    :max="item.recommended_amount"
                    type="number"
                    :readonly="item.recommended_amount === 1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 class="equipment__title">{{ $t('equipment.table.title2') }}</h2>
      <div class="equipment__table">
        <div v-for="purchase of purchases" :key="purchase.id" class="equipment__line equipment__line--sale">
        <span class="equipment__line-name">
          {{ purchase.public_name }}
          <span class="equipment__line-measurement">({{ purchase.measurement.name }})</span>
        </span>
          <div class="equipment__line-holder">
            <span class="equipment__line-saleprice">
              ${{ purchase.price }}
              <span v-if="purchase.out_of_stock" class="equipment__line-saleout">{{ $t('equipment.table.out') }}</span>
            </span>
            <div class="equipment__line-controls" :class="{ 'equipment__hide': purchase.out_of_stock }">
              <button class="equipment__line-control" :class="{'equipment__line-control--inactive': purchase.count === 0}" @click="onChangeCount(purchase, -1)">
                <svg width="12" height="3" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 .657h12v2H0v-2Z"/>
                </svg>
              </button>
              <span class="equipment__line-purchase-count">{{ purchase.count }}</span>
              <button class="equipment__line-control" @click="onChangeCount(purchase, 1)">
                <svg width="12" height="13" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.657h12v2H0v-2Z"/>
                  <path d="M5 12.657v-12h2v12H5Z"/>
                </svg>
              </button>
            </div>
            <span
              class="equipment__line-purchase-price"
              :class="{ 'equipment__hide': purchase.out_of_stock }"
            >
              ${{ purchase.total_price }}
            </span>
          </div>
        </div>
      </div>
      <app-cells position="end">
        <h2 class="equipment__title">{{ $t('equipment.total') }}: ${{ totalCost }}</h2>
      </app-cells>
      <app-cells position="end" :indent="false">
        <app-button ref="submit" @click.native="onSendForm">Save</app-button>
      </app-cells>
      <modal
          name="confirm-modal"
          :width="400"
          :height="'auto'"
          :scrollable="true"
          :adaptive="true"
      >
        <div class="modal modal--confirm">
          <div class="modal__top">
            <img src="@/assets/img/warning-modal-icon.svg" alt="warning">
            <span>{{ $t('modal-back.title') }}</span>
          </div>
          <div class="modal__text">{{ $t('modal-back.text') }}</div>
          <app-cells position="center" :indent="false">
            <app-button @click.native="onHideModal" theme="dark">{{ $t('modal-back.stay') }}</app-button>
            <app-button @click.native="onRouteHome">{{ $t('modal-back.leave') }}</app-button>
          </app-cells>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import { getRentalCatalog, patchEquipmentChecklist } from '@/http'
import { format, parse } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en'

export default {
  name: 'EquipmentChecklist',
  data() {
    return {
      is_loaded: false,
      group: {
        route: {},
      },
      client: {},
      equipments: [],
      purchases: [],
    }
  },
  created() {
    this.getChecklist()
  },
  computed: {
    totalCost() {
      if (!this.purchases.length || !this.equipments.length) return 0
      return this.purchases.map(item => item.total_price).reduce((a, b) => a + b, 0) + this.equipments.filter(item => item.status).map(item => item.total_price).reduce((a, b) => a + b, 0)
    },
    contentLanguage() {
      return localStorage.getItem('content_language')
    },
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
  },
  methods: {
    getChecklist() {
      getRentalCatalog(this.$route.params.hash, this.$route.params.groupId)
        .then(response => {
          this.group = response.data.group_client.group
          this.client = response.data.group_client.client
          this.equipments = response.data.equipments.map(item => {
            return {
              ...item,
              selected_size: item.selected_size ? item.selected_size : item.sizes[0],
              count: item.recommended_amount === 1 ? 1 : item.count,
              total_price: item.recommended_amount === 1 ? 1 * item.price : item.total_price ? item.total_price : 0
            }
          })
          this.purchases = response.data.purchases.map(item => {
            return {
              ...item,
              count: item.count || 0,
              total_price: item.total_price || 0,
            }
          })
          this.is_loaded = true
        })
    },
    onShowModal() {
      this.$modal.show('confirm-modal')
    },
    onHideModal() {
      this.$modal.hide('confirm-modal')
    },
    onRouteHome() {
      this.$router.push({name: 'home', params: {hash: this.$route.params.hash}})
    },
    formatDate(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'DD MMMM YYYY', {locale: this.contentLanguage === 'ru' ? ruLocale : enLocale})
    },
    onSetCount(item) {
      if (item.count > item.recommended_amount) item.count = item.recommended_amount
      if (item.count === 0) item.count = 1
      item.total_price = item.count * item.price
    },
    onChangeCount(purchase, value) {
      purchase.count += value
      purchase.total_price = purchase.count * purchase.price
    },
    onSendForm() {
      this.$refs.submit.preload = true
      const data = {
        equipments: this.equipments.map(item => {
          if (item.status === false) {
            return {
              status: item.status,
              category: item.id
            }
          }
          return {
            status: item.status,
            count: item.count || 1,
            size: item.selected_size.id,
            category: item.id
          }
        }),
        purchases: this.purchases.filter(item => item.count).map(purchase => {
          return {
            item: purchase.id,
            count: purchase.count
          }
        })
      }
      patchEquipmentChecklist(this.$route.params.hash, this.$route.params.groupId, data)
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notify.data_save'),
          })
          this.$router.push({ name: 'home', params: { hash: this.$route.params.hash } })
        })
    }
  }
}
</script>

<style scoped lang="sass">
.equipment__name
  margin-bottom: 25px
  font-size: 18px
  font-weight: 600

.equipment__infos
  display: flex
  flex-wrap: wrap
  gap: 15px 30px
  margin-bottom: 20px

.equipment__info
  display: flex
  gap: 10px

  span
    font-size: 13px
    font-weight: 500

    &:first-child
      color: #5e5e5e

.equipment__text
  margin-bottom: 40px
  font-size: 13px
  line-height: 20px

.equipment__title
  margin-bottom: 16px
  font-size: 16px
  font-weight: 600

.equipment__table
  margin-bottom: 50px

  &:last-child
    margin-bottom: 0

.equipment__line
  display: flex
  justify-content: space-between
  padding: 5px 0
  border-bottom: 1px solid #eaeaea

  &:first-child
    border-top: 1px solid #eaeaea

  +max-w($mobile_xl)
    flex-direction: column

.equipment__line--sale
  align-items: center

  +max-w($mobile_xl)
    flex-direction: row
    gap: 10px

.equipment__line-left
  position: relative
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: space-between
  gap: 0 20px
  max-width: 637px
  padding-right: 85px

  +max-w($mobile_xl)
    max-width: unset
    padding-right: 0

.equipment__line-switcher
  flex-shrink: 0
  display: flex
  align-items: center
  gap: 10px

.equipment__line-switcher--hide
  +max-w($mobile_xl)
    display: none

.equipment__line-have
  flex-shrink: 0
  color: #5f5f5f
  font-size: 13px
  transition: color 0.3s

.equipment__line-have--active
  color: $color-text-base

.equipment__line-name
  font-size: 14px
  font-weight: 500

.equipment__line-out
  position: absolute
  right: 0
  flex-shrink: 0
  padding: 3px
  border-radius: 4px
  color: #535353
  background-color: #ececec
  font-size: 10px

.equipment__line-right
  flex-shrink: 0
  display: flex
  align-items: center
  gap: 20px
  transition: max-height 0.3s, padding-top 0.3s, opacity 0.3s
  opacity: 0
  pointer-events: none

  +max-w($mobile_xl)
    max-height: 0

.equipment__line-right--show
  opacity: 1
  pointer-events: auto

  +max-w($mobile_xl)
    padding-top: 20px
    max-height: 58px

.equipment__line-price
  min-width: 45px

  +max-w($mobile_xl)
    margin-right: auto

.equipment__line-purchase-price
  min-width: 45px
  text-align: right

.equipment__line-size
  width: 160px
  text-align: center

.equipment__line-thumb
  color: #979797
  font-size: 13px

.equipment__line-count
  display: flex
  align-items: center
  gap: 10px

  span
    min-width: 30px
    font-size: 14px

.equipment__line-input
  max-width: 50px

  input
    text-align: center

.equipment__line-holder
  display: flex
  align-items: center
  gap: 60px

  +max-w($mobile_sm)
    gap: 20px

.equipment__line-controls
  display: flex
  align-items: center
  gap: 19px

  span
    font-size: 15px

  +max-w($mobile_sm)
    gap: 10px

.equipment__line-control
  display: flex
  align-items: center
  justify-content: center
  width: 40px
  height: 40px
  border: 1px solid #e5e5e5
  border-radius: 50%
  transition: background-color 0.3s, border-color 0.3s
  touch-action: manipulation

  &:active
    border-color: $color-theme
    background-color: $color-theme

    svg
      fill: #fff

  @media (hover: hover)
    &:hover,
    &:focus-visible
      border-color: $color-theme
      background-color: $color-theme

      svg
        fill: #fff

  svg
    transition: fill 0.3s

  +max-w($mobile_sm)
    width: 30px
    height: 30px

.equipment__line-control--inactive
  pointer-events: none

  svg
    fill: #a0a0a0

.equipment__line-purchase-count
  min-width: 20px
  text-align: center

.equipment__line-measurement
  color: #a0a0a0

.equipment__hide
  opacity: 0
  pointer-events: none

.equipment__line-saleprice
  position: relative

.equipment__line-saleout
  position: absolute
  left: calc(100% + 10px)
  padding: 3px
  border-radius: 4px
  color: #535353
  background-color: #ececec
  font-size: 10px
  width: max-content
  text-align: center
</style>
